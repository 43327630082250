import(/* webpackMode: "eager", webpackExports: ["notification","config","open"] */ "__barrel_optimize__?names=notification!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["queryClient","clear","setQueryData"] */ "/app/src/api/query/reactQuery/index.ts");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/components/notification.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/pageComponents/HomePageComponent.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/pageComponents/westfield/WestfieldHomePageComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/Button/Button.tsx");
