import React from "react";
import { InputNumber as InputNumberAntd } from "antd";
import classNames from "classnames";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

// interfaces
import { InputNumberProps } from "./IInput";

// components
import LabelErrorProvider from "../../components/LabelErrorProvider";

// assets
import styles from "../../assets/styles/shared/input.module.scss";

function InputNumber(props: InputNumberProps) {
  const {
    label,
    error,
    className,
    wrapperClassName,
    fullWidth,
    addonBefore,
    addonAfter,
    ...rest
  } = props;

  const inputNumberClassName = classNames(styles["locl-input-number"], className, {
    [styles["locl-input-number_full-width"]]: !!fullWidth,
  });

  const inputWrapperClassName = classNames(styles["locl-input-base-wrapper"], {
    [styles["locl-input-base-wrapper_width-addon-before"]]: !!addonBefore,
    [styles["locl-input-base-wrapper_width-addon-after"]]: !!addonAfter
  });

  return (
    <LabelErrorProvider label={label} error={error} className={wrapperClassName}>
      <div className={inputWrapperClassName}>
        {addonBefore && (
          <span className={`${styles["locl-input__addon"]} ${styles["locl-input__addon_before"]}`}>
            {addonBefore}
          </span>
        )}
        <InputNumberAntd
          controls={{
            upIcon: (
              <FaChevronUp
                className={
                  styles["locl-input-number__control-icon locl-input-number__control-icon_up"]
                }
              />
            ),
            downIcon: (
              <FaChevronDown
                className={
                  styles["locl-input-number__control-icon locl-input-number__control-icon_down"]
                }
              />
            )
          }}
          {...rest}
          className={inputNumberClassName}
        />
        {addonAfter && (
          <span className={`${styles["locl-input__addon"]} ${styles["locl-input__addon_after"]}`}>
            {addonAfter}
          </span>
        )}
      </div>
    </LabelErrorProvider>
  );
}

export default InputNumber;
