"use client";

import React from "react";
import uniq from "lodash/uniq";
import flattenDeep from "lodash/flattenDeep";
import { BsArrowRightShort } from "react-icons/bs";
import * as yup from "yup";
import { useFormik } from "formik";
import { HiOutlineLocationMarker, HiOutlineCalendar } from "react-icons/hi";

// components
import Button from "@/shared/Button";
import Dropdown from "@/shared/Dropdown";
import WhereSelect from "@/components/WhereSelect";
import SearchFiltersDropdownButton from "@/components/SearchFiltersDropdownButton";
import WhenDropdownPicker, { IWhenDropdownPicker } from "@/components/WhenDropdownPicker";
import ShopIcon from "@/components/icons/ShopIcon";

// helpers
import { useDimension } from "@/hooks/useDimensions";
import queryString from "@/helpers/queryString";
import useSearchFiltersData from "@/hooks/useSearchFiltersData";
import useRouter from "@/hooks/useRouter";

// interfaces
import { DropdownMenuItemProps } from "@/shared/Dropdown/IDropdown";

// assets
import westfieldLandingStyles from "@/assets/styles/pages/westfield-home-page.module.scss";
import styles from "@/assets/styles/pages/lolive/lolive-search-bar.module.scss";


interface FormValuesInterface {
  purpose__in: number | null;
  city__in: number[] | [];
  date: IWhenDropdownPicker["value"] | null;
  when__in: number | null;
}

const validationSchema = yup.object().shape({
  city__in: yup.array()
    .nullable()
    .min(1, "Oops! This field is required!")
    .required("Oops! This field is required!"),
  date: yup.array().nullable(),
  purpose__in: yup.number().nullable(),
})

const formatDates = (dates: IWhenDropdownPicker["value"], width: number) => {
  if (!dates) {
    return "";
  }

  return width < 1000
    ? `${dates[0]?.format(" DD MMM YYYY")} - ${dates[1]?.format(" DD MMM YYYY")}`
    : `${dates[0]?.format("MMM DD, YYYY")} - ${dates[1]?.format("MMM DD, YYYY")}`;
};


function LoliveSearchBar({ onSearch }: { onSearch?: () => void }) {
  const { push } = useRouter();

  const [width] = useDimension();
  const isMobile = width < 820;

  const columnCellClassName = styles["locl-lolive-search-bar__column-cell"];

  // methods
  const onSubmit = (values: FormValuesInterface) => {
    try {
      const startDate = values.date && values.date[0] ? values.date[0].format("YYYY-MM-DD") : null;
      const endDate = values.date && values.date[1] ? values.date[1].format("YYYY-MM-DD") : null;

      const state = {
        purpose__in: values.purpose__in,
        city__in: uniq(flattenDeep(values.city__in)).join("__"),
        start_date: startDate,
        end_date: endDate,
        when__in: values.when__in,
      };
  
      const query = queryString.stringify(state);

      onSearch && onSearch();
      push(`/search-results?${query}`);
    } catch (error) {
      console.error(error);
    }
  };

  const { values, errors, setFieldValue, handleSubmit, validateField } = useFormik<FormValuesInterface>({
    initialValues: {
      purpose__in: null,
      city__in: [],
      date: null,
      when__in: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: onSubmit,
  });

  const {
    purposes,
    selectedCityOptions,
    selectedCity,
    selectedPurpose
  } = useSearchFiltersData({ city__in: values.city__in, purpose__in: values.purpose__in });

  const renderFilterValue = (valuesList: DropdownMenuItemProps[]) => {
    if (!valuesList.length) {
      return null;
    }

    return valuesList.length > 1
      ? `${valuesList[0]?.label}+${valuesList.length - 1}`
      : valuesList[0]?.label;
  };

  const handelFileChange = async (key: string, value: unknown) => {
    await setFieldValue(key, value);
    validateField(key);
  };

  if (isMobile && process.env.NEXT_PUBLIC_APP_WL_CODE !== "LOCL") {
    return (
      <Button
        danger={process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL"}
        size="large"
        to="/search-results"
        data-testid="locl-lolive-search-bar-button-search"
        iconRight={<BsArrowRightShort className={styles["locl-lolive-search-bar__button-icon"]}/>}
      >
        Search
      </Button>
    );
  }

  // renders
  return (
    <form className={styles["locl-lolive-search-bar"]} onSubmit={handleSubmit}>
      <div className={columnCellClassName}>
        <Dropdown
          placement="bottomLeft"
          autoAdjustOverflow={false}
          dropdownRender={() => (
            <WhereSelect
              value={selectedCity}
              overlayClassName={
                process.env.NEXT_PUBLIC_APP_WL_CODE === "WERI"
                  ? westfieldLandingStyles["locl-when-dropdown-picker-overlay-weri"]
                  : ""
              }
              onChange={(e: number[]) => handelFileChange("city__in", e)}
            />
          )}
        >
          <SearchFiltersDropdownButton
            label="Select location*"
            icon={<HiOutlineLocationMarker/>}
            value={renderFilterValue(selectedCityOptions)}
            data-testid="locl-where-dropdown"
            error={errors?.city__in}
            type={isMobile ? "column" : "row"}
          />
        </Dropdown>
      </div>
      { !isMobile && (
        <div className={columnCellClassName}>
          <WhenDropdownPicker
            value={values.date || [null, null]}
            onChange={e => handelFileChange("date", (!!e[0] && !!e[1]) ? e : null)}
            onPresetClick={e => setFieldValue("when__in", e)}
            overlayClassName={process.env.NEXT_PUBLIC_APP_WL_CODE === "WERI" ? westfieldLandingStyles["locl-when-dropdown-picker-overlay-weri"] : ""}
          >
            <SearchFiltersDropdownButton
              label="Select dates"
              icon={<HiOutlineCalendar/>}
              value={values.date ? formatDates(values.date, 1200) : null}
              data-testid="locl-when-dropdown"
              isOptional
            />
          </WhenDropdownPicker>
        </div>
      )}
      {
        !isMobile && process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" && (
          <div className={columnCellClassName}>
            <Dropdown
              menu={{ items: purposes }}
              autoAdjustOverflow={false}
              placement="bottomLeft"
              onChange={e => handelFileChange("purpose__in", (e as DropdownMenuItemProps).value)}
            >
              <SearchFiltersDropdownButton
                label="Use of space"
                icon={<ShopIcon/>}
                value={selectedPurpose?.label as string}
                data-testid="locl-purpose-dropdown"
              />
            </Dropdown>
          </div>
        )
      }
      <Button
        danger
        size="large"
        htmlType="submit"
        fullWidth
        data-testid="locl-lolive-search-bar-button-search"
      >
        Start search
        <BsArrowRightShort className={styles["locl-lolive-search-bar__button-icon"]}/>
      </Button>
    </form>
  )
}

export default LoliveSearchBar;
