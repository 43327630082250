// assets
import AllCampaignsIcon from "@/components/icons/AllCampaignsIcon";
import BookingsIcon from "@/components/icons/BookingsIcon";
import MyMessagesIcon from "@/components/icons/MyMessagesIcon";
import UserProfile from "@/components/icons/UserProfile";
import SettingsIcon from "@/components/icons/SettingsIcon";
import LegalIcon from "@/components/icons/LegalIcon";
import SupportIcon from "@/components/icons/SupportIcon";

export const SHOW_HEADER_THRESHOLD = 50;
export const PAGES_WITH_STICKY_HEADER = ["/lolive/", "/lolive"];

export const getHeaderNavigationLinks = (
  withList: boolean,
  isAuthorized: boolean
) => {
  return [
    { label: "home", value: "/" },
    { label: "lo:live", value: isAuthorized ? "/go-search" : "/lolive" },
    { label: "blog", value: "/blog" },
    { label: "collections", value: "/collections" },
    withList && { label: "list your space", value: "/landlord" }
  ].filter(Boolean);
};

export const accountLinks = [
  { label: "Account", value: "/profile", icon: <UserProfile className="locl-auth-header__menu-icon" /> },
  { label: "Settings", value: "/settings/organisation-members", icon: <SettingsIcon className="locl-auth-header__menu-icon" /> },
];

export const getHirerInfoLinks = (unreadMessages: number | null) => [
  { label: "Campaigns", value: "/campaigns", icon: <AllCampaignsIcon className="locl-auth-header__menu-icon" /> },
  { label: "Bookings", value: "/bookings", icon: <BookingsIcon className="locl-auth-header__menu-icon" /> },
  { label: "Messages", value: "/my-messages", icon: <MyMessagesIcon className="locl-auth-header__menu-icon" />, badge: unreadMessages },
];

export const supportsLinks = [
  { label: "Legal", value: "/terms-of-use", icon: <LegalIcon className="locl-auth-header__menu-icon" /> },
  { label: "Support", value: "/support", icon: <SupportIcon className="locl-auth-header__menu-icon" /> },
]
