const API_ROUTES = {
  landing: {
    locationTypes: "/landing-page/location-types",
    eventTypes: "/landing-page/event-types",
    brandLogos: "/landing-page/brand-logos",
    heroSection: "/landing-page/hero-section",
    spacesImages: "/landing-page/space-images",
    placeImages: "/landing-page/place-images",
    postContactUs: "/landing-page/need-us-to-help",
    popularDestinations: "/landing-page/popular-destinations",
    environments: "/landing-page/environments",
    carouselLocations: "/landing-page/carousel-locations",
    collections: "/landing-page/collections",
    statisticNumbers: "/landing-page/numbers",
    announcementBanner: "/landing-page/announcement-banner",
  },
  spacesUrls: {
    spaces: "/spaces",
    spacesShort: "/spaces/short",
    searchSpaces: "/search/spaces",
    spaceSingle: "/spaces/:slug",
    spaceClone: "/spaces/:slug/clone",
    spaceSingleStatus: "/spaces/:slug/status",
    spaceActivities: "/spaces/:slug/activities",
    spaceCalendarAll: "/spaces/calendar",
    spaceActivity: "/spaces/:slug/calendar/:activityId",
    spaceCalendar: "/spaces/:slug/calendar",
    spaceCalendarRecord: "/spaces/:slug/calendar/:recordId",
    spacePublish: "/spaces/:slug/publish",
    spaceUnpublish: "/spaces/:slug/unpublish",
    spaceRestore: "/spaces/:slug/restore",
    spaceArchive: "/spaces/:slug/archive",
    spaceRecordPageView: "/spaces/:slug/record-page-view",
    spaceAvailableMonth: "/spaces/:slug/available-month",
    spaceBookingRequirements: "/spaces/:slug/booking-requirements",
    spacesUpdateAvailability: "/spaces/update-availability",
    spacesChangePosition: "/spaces/change-position",
  },
  countryStateCityUrls: {
    countries: "/countries",
    countriesFilters: "/countries-filters",
    regions: "/countries/:countryId/regions",
    cities: "/countries/:countryId/regions/:regionId/cities"
  },
  purposesUrls: {
    purposes: "/purposes",
    purposeSingle: "/purposes/:id",
  },
  calendarUrls: {
    calendar: "/calendar",
    whenSelectorDates: "/calendar/when-selector-dates",
  },
  account: {
    registerHirer: "/account/register/hirer",
    registerLandlord: "/account/register/landlord",
    registerReactivate: "/account/register/reactivate",
    activateAccountToken: "/account/register/activate/:uid/:token",
    token: "/account/token",
    refreshToken: "/account/token/refresh",
    profileMe: "/account/profile/me",
    managersAndStaff: "/account/profile/me/managers-and-staff",
    accountUsers: "/account/users",
    resetPasswordComplete: "/account/password/reset_complete",
    resetPassword: "/account/password/reset",
    registerSetPassword: "/account/password/register/:userType/set",
    inviteSetPassword: "/account/password/invite/:userType/set",
    checkUserActive: "/account/profile/check-user-active",
    logOut: "/account/logout",
    accountDelete: "/account/delete",
    accountDeleteMember: "/account/delete/member",
    passwordChange: "/account/password/change",
    accessLevel: "/account/access_level/:type",
    profilePreferences: "/account/profile/preferences",
    invitationUrl: "/account/:type/invitations",
    users: "account/users/:userId",
    marketingPopup: "/account/marketing-popup",
    accountProfileSettings: "/account/profile/setting",
    isAuthenticated: "account-check-is-authenticated",
    registerUnsubscribe: "/account/register/unsubscribe/:uid/:token",
    changeEmail: "/account/profile/change-email",
    confirmEmailChange: "/account/profile/confirm-email-change",
    profileVerification: "/account/profile/verification",
    confirmProfileVerification: "/account/profile/confirm-verification",
    resendActivationEmail: "/account/register/resend-activation-email",
  },
  locationsUrls: {
    locations: "/locations",
    locationsSingle: "/locations/:slug",
    locationPublish: "/locations/:slug/publish",
    locationsShort: "/locations/short",
    updateAvailability: "/locations/:slug/update-availability",
    quickSearch: "/locations/search",
    locationCalendar: "/locations/:slug/calendar",
    locationCalendarBulkStatusUpdate: "/locations/:slug/calendar/bulk-status-update",
    locationRestore: "/locations/:slug/restore",
    locationArchive: "/locations/:slug/archive",
    locationMembers: "/locations/:slug/members",
    locationConversion: "/locations/:slug/conversion",
    locationPartnerEnquiry: "/locations/partner-enquiry",
    locationCampaignItems: "/locations/:slug/campaign/:id/items",
    locationNudge: "/locations/:slug/nudge",
    locationNudgeUpdate: "/locations/:slug/nudge/:id",
    locationRecordPageView: "/locations/:slug/record-page-view",
    locationCalendarEvents: "/locations/:slug/calendar"
  },
  campaign: {
    campaigns: "/campaigns",
    singleCampaign: "/campaigns/:id",
    singleCampaignBookingForm: "/campaigns/:id/last-booking-form",
    singleCampaignReleaseAllOptions: "/campaigns/:id/release-all-options",
    singleCampaignItems: "/campaigns/:id/items",
    singleCampaignPeriods: "/campaigns/:id/periods",
    singleCampaignSinglePeriod: "/campaigns/:id/periods/:periodId",
    singleCampaignSingleItem: "/campaigns/:id/items/:itemId",
    singleCampaignSpaces: "/campaigns/:id/spaces",
    singleCampaignSchedules: "/campaigns/:id/schedules",
    singleCampaignSingleSchedules: "/campaigns/:id/schedules/:scheduleId",
    singleCampaignSingleSchedulesItems: "/campaigns/:id/schedules/:scheduleId/items",
    singleCampaignSpacesExport: "/campaigns/:id/spaces/export",
    singleCampaignBulkDelete: "/campaigns/:id/bulk-delete",
    singleCampaignArchive: "/campaigns/:id/archive",
    singleCampaignExtendArchivalDate: "/campaigns/:id/extend-archival-date",
    singleCampaignRequestArchivalDateExtension: "/campaigns/:id/request-campaign-archival-date-extension",
    singleCampaignUnarchive: "/campaigns/:id/unarchive",
    singleCampaignBulkAddSpaces: "/campaigns/:id/bulk-add-spaces",
    singleCampaignRequestUnarchival: "/campaigns/:id/request-campaign-unarchival",
    addSpaceToCampaign: "/campaigns/:campaignId/items",
    addMembersToCampaign: "/campaigns/:id/members/bulk",
    quickCreate: "/campaigns/quick_create",
    shortCampaigns: "/campaigns/short",
    brandLogos: "/brand-logos/:id",
    agencyLogos: "/agency-logos/:id",
    singleCampaignNudge: "/campaigns/:id/nudge/:nudgeId",
    downloadInstallPack: "/campaigns/:id/spaces/download-install-pack/:spaceId",
  },
  previewUrls: {
    previewSpace: "/preview/spaces/:slug",
    previewLocation: "/preview/locations/:slug",
  },
  modalWindowUrls: {
    modalWindow: "/modal-window",
  },
  currenciesUrls: {
    appCurrentCurrency: "app-current-currency",
    currencies: "/currencies",
    currencySingle: "/currencies/:id",
  },
  fileUploadUrls: {
    fileUpload: "/file-upload"
  },
  companyTypesUrls: {
    companyTypes: "/company-types"
  },
  billingInformationUrls: {
    billingInformation: "/billing-information",
    billingInformationSingle: "/billing-information/:id",
  },
  contactDetailsUrls: {
    contactDetails: "/contact-details"
  },
  bookingsUrls: {
    bookingsHistory: "/bookings/history",
    bookingSingle: "/bookings/:id",
    bookingAddPoNumber: "/bookings/:id/add-po-number",
    bookingAddBillingInformation: "/bookings/:id/add-billing-information",
    bookingCancelRequest: "/bookings/:id/cancel-request",
    bookingAcceptProposal: "/bookings/:id/accept_proposal",
    bookingRejectProposal: "/bookings/:id/reject_proposal",
    bookingBulkDecline: "/bookings/bulk-decline",
    bookingAmendment: "/bookings/:id/amendments",
  },
  options: {
    optionsBulkRelease: "/options/bulk_release",
    optionsRelease: "/options/:id/release",
    optionsExtensions: "/options/:id/extensions",
    optionsAcceptProposal: "/options/:id/accept_proposal",
    optionsRejectProposal: "/options/:id/reject_proposal",
    optionsAmendment: "/options/:id/amendments",
    optionsAcceptAmendment: "/options/:id/amendments/:amendmentId/accept",
    optionsRejectAmendment: "/options/:id/amendments/:amendmentId/reject",
    optionsBulkDecline: "/options/bulk-decline",
    optionsSingle: "/options/:id",
  },
  sectorsUrls: { sectors: "/sectors", },
  notificationUrls: {
    notification: "/notification",
    notificationCount: "/notification/count",
    viewNotification: "/notification/:id/view",
    hideNotification: "/notification/:id/hide",
    unhideNotification: "/notification/:id/unhide",
    viewAllNotifications: "/notification/view_all",
  },
  criteriasUrls: {
    criterias: "/criterias",
    criteriasById: "/criterias/:id"
  },
  searchUrls: {
    resultsCount: "/search/results-count",
    search: "/search/locations",
    searchOptions: "/search/options",
    searchBookings: "/search/bookings",
  },
  conversationsUrls: {
    conversationMessages: "/conversations/messages",
    conversationMessagesPage: "/conversations/messages-page",
    singleConversation: "/conversations/:id",
    conversationsMessages: "/conversations/rooms/:id/messages",
    conversationsRooms: "/conversations/user-rooms",
    conversationsProactiveMessageAnalytics: "/conversations/proactive-message-analytics",
    conversationsSingleRoom: "/conversations/user-rooms/:id",
    conversationsRoomSync: "/conversations/rooms/:id/update_last_sync",
    conversationsOverviewMessages: "/conversations/overview/messages",
    conversationsOverviewMessageMarkAsRead: "/conversations/overview/messages/:id/mark-read",
  },
  collections: "/collections",
  calculatePrice: "/calculation/price",
  sectors: "/sectors",
  popularSearches: "/popular_searches",
  logs: "/logs",
  slots: "/slots",
  closedDaysUrls: {
    closedDays: "/closed-days",
    closedDaysBulk: "/closed-days/bulk",
    closedDaysSingle: "/closed-days/:id",
  },
  redirect: "/redirect",
  dynamicPricesUrls: {
    dynamicPrices: "/dynamic-prices",
    dynamicPricesSingle: "/dynamic-prices/:id",
  },
  floors: {
    all: "/floors",
    single: "/floors/:id",
  },
  features: {
    all: "/features",
    single: "/features/:id",
  },
  proximities: {
    all: "/proximities",
    single: "/proximities/:id",
  },
  reportsUrls: {
    reports: "/reports/landlord-companies",
    reportsByLocation: "/reports/locations/:id",
    forecast: "/reports/landlord-companies/forecast",
    forecastByLocation: "/reports/locations/:id/forecast",
    reportsByLocationNudge: "/analytics/nudges",
    reportsMetabase: "/reports/metabase",
    reportsMetabaseReports: "/reports/metabase/reports",
  },
  locationOrdersUrls: { locationOrders: "/location-orders" },
  support: {
    platformVideos: "/support/platform-videos",
    weekdays: "/support/weekdays",
  },
  analytics: {
    dismissBanner: "/analytics/dismiss-banner",
    bannerClick: "/analytics/record-banner-click",
    showResults: "/analytics/show-results",
    amplificationBanner: "/analytics/amplification-banner",
    gettingStartedVideo: "/analytics/getting-started-video",
    recordSiteVisitRequest: "/analytics/record-site-visit-request",
    advancedFilter: "/analytics/advanced-filters",
    userEngagement: "/analytics/user-engagement",
  },
  demoRequests: "/demo_requests",
  geographicRegions: "/geographic-regions",
  platforms: "/platforms",
  ops: {
    login: "/ops/token",
    platformAnalytics: "/ops/platform-analytics",
    dashboardReports: "/ops/dashboard/reports",
    adminDashboard: "/ops/admin-dashboard/:type",
    accounts: {
      hirers: "/ops/accounts/hirers",
      hirersCreate: "/ops/accounts/hirers/create",
      hirersChangeEmail: "/ops/accounts/hirers/:id/change-email",
      hirersResetPassword: "/ops/accounts/hirers/:id/reset-password",
      hirerSingle: "/ops/accounts/hirers/:id",
      hirerCompanies: "/ops/accounts/hirer-companies",
      landlords: "/ops/accounts/landlords",
      landlordsCreate: "/ops/accounts/landlords/create",
      landlordSingle: "/ops/accounts/landlords/:id",
      landlordChangeEmail: "/ops/accounts/landlords/:id/change-email",
      landlordResetPassword: "/ops/accounts/landlords/:id/reset-password",
      landlordCompanies: "/ops/accounts/landlord-companies",
      locationsGroups: "/ops/accounts/location-groups",
    },
  },
  landlordPortalUrls: {
    overviewRevenue: "/landlord-portal/overview/revenue",
    overviewRevenueByMonth: "/landlord-portal/overview/revenue-by-month",
    listingToUpdate: "/landlord-portal/overview/listing-to-update",
    locationAvailability: "/landlord-portal/overview/location-availability",
    openRequest: "/landlord-portal/overview/open-requests",
  },
  customQueryUrls: {
    landlordSelectedLocation: "/landlord-selected-location",
  },
};

export const WEBSOCKET_ROUTES = {
  unreadMessagesCount: "unread-messages-count",
  conversations: "conversations",
  checkUserDeactivated: "check-user-deactivated",
  landlordDeals: "landlord-portal-deals",
};

export default API_ROUTES;
