import React from "react";
import { Tooltip, TooltipProps as AntdTooltipProps } from "antd";
import { HiInformationCircle } from "react-icons/hi";
import classNames from "classnames";

// assets
import styles from "@/assets/styles/components/info-tooltip.module.scss";


export type TInfoTooltipProps = AntdTooltipProps & {
  primary?: boolean;
}

function InfoTooltip({ align, overlayClassName, primary, ...props }: TInfoTooltipProps) {
  const className = classNames(
    styles["locl-info-tooltip"],
    overlayClassName,
  );

  return (
    <Tooltip {...props} align={align || { offset: [0, 5] }} overlayClassName={className}>
      <span className={classNames(
        styles["locl-info-tooltip-icon"],
        {
          [styles["locl-info-tooltip-icon__primary"]]: primary,
        }
      )}>
        <HiInformationCircle />
      </span>
    </Tooltip>
  );
}

export default InfoTooltip;
